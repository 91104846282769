<template>
    <div>
        <div v-if="editor" class="rich_text-menubar dF aC" :class="all ? 'pb-1' : 'pb-3'">
            <a-tooltip overlayClassName="change-tooltip-color" title="Bold" placement="top">
				<button @click="toggleBold" :class="{ 'is-active': editor.isActive('bold') }">
                    <a-icon type="bold" :class="{ 'text-med-gray': !editor.isActive('bold') }" ></a-icon>
                </button>
            </a-tooltip>
            <a-tooltip overlayClassName="change-tooltip-color" title="Italic" placement="top">
                <button @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
                    <a-icon type="italic" :class="{ 'text-med-gray': !editor.isActive('italic') }" ></a-icon>
                </button>
            </a-tooltip>
            <a-tooltip overlayClassName="change-tooltip-color" title="Underline" placement="top">
                <button @click="toggleUnderline" :class="{ 'is-active': editor.isActive('underline') }">
                    <a-icon type="underline" :class="{ 'text-med-gray': !editor.isActive('underline') }" ></a-icon>
                </button>
            </a-tooltip>
            <a-tooltip overlayClassName="change-tooltip-color" title="Line Height" placement="top">
                <MoreActions trigger="hover" shape="slot" :items="heightChoices" @action="applyHeight">
                    <button>
                        <a-icon type="line-height" ></a-icon>
                    </button>
                </MoreActions>
            </a-tooltip>
			<template v-if="all">
				<a-tooltip overlayClassName="change-tooltip-color" title="Ordered List" placement="top">
					<button @click="toggleOrderedList" :class="{ 'is-active': editor.isActive('orderedList') }">
						<a-icon type="ordered-list" :class="{ 'text-med-gray': !editor.isActive('orderedList') }" ></a-icon>
					</button>
				</a-tooltip>
				<a-tooltip overlayClassName="change-tooltip-color" title="Bullet List" placement="top">
					<button @click="toggleBulletList" :class="{ 'is-active': editor.isActive('bulletList') }">
						<a-icon type="unordered-list" :class="{ 'text-med-gray': !editor.isActive('bulletList') }" ></a-icon>
					</button>
				</a-tooltip>
				<a-tooltip overlayClassName="change-tooltip-color" title="Heading" placement="top">
					<MoreActions trigger="hover" shape="slot" :items="headingChoices" @action="applyHeading">
						<button>
							<div>H1</div>
						</button>
					</MoreActions>
				</a-tooltip>
			</template>
            <a-popover :visible="showLinkOptionsBoolean" title="Apply Link" trigger="click">
                <div class="button-editor-container" slot="content">
                    <template v-if="builder !== 'email'">
                        <p style="line-height:1" class="my-2">Link Type</p>
                        <a-select class="mb-2 w-full" v-model="form.type" :options="linkType" />
                    </template>

                    <template v-if="form.type === 'link'">
                        <p style="line-height:1" class="my-2">External Link (URL)</p>
                        <a-input class="w-full" v-model="form.value" />
                    </template>

                    <template v-else-if="form.type === 'section'">
                        <p style="line-height:1" class="my-2">Select a Section/Modal</p>
                        <a-select class="w-full" v-model="form.value" :options="pageSectionsOptions" />
                    </template>
                    <template v-else-if="form.type === 'page'">
                        <p style="line-height:1" class="my-2">Select a Page</p>
                        <a-select class="w-full" v-model="form.value" :options="pagesOptions" />
                    </template>
                    <div class="mt-4 dF" style="gap:1ch;">
                        <a-button type="primary" @click="applyLink">Apply</a-button>
                        <a-button @click="showLinkOptionsBoolean = false">Cancel</a-button>
                    </div>
                </div>
                <a-tooltip overlayClassName="change-tooltip-color" title="Link" placement="top">
                    <button @click="showLinkOptions">
                        <a-icon type="link" :class="{'text-med-gray':!editor.isActive('link')}" ></a-icon>
                    </button>
                </a-tooltip>
            </a-popover>

			<!-- Superscript button -->
			<a-tooltip overlayClassName="change-tooltip-color" title="Superscript" placement="top">
				<button @click="editor.chain().focus().toggleSuperscript().run()" :class="{ 'is-active': editor.isActive('superscript') }">
					<svg :fill="editor.isActive('superscript') ? '#000' : '#aaa'" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 31.773 31.773" xml:space="preserve"> <g> <path d="M15.383,18.945l7.761,12.506h-5.398l-3.48-5.825c-0.533-0.9-1.041-1.812-1.527-2.736l-0.498-0.959l-0.515-0.941h-0.07 l-0.515,0.959c-0.617,1.162-1.315,2.389-2.099,3.68l-3.498,5.826H0l7.959-12.508L0.57,7.206h5.489l3.23,5.399 c0.512,0.854,0.989,1.693,1.438,2.521l0.445,0.852l0.442,0.855h0.07c0.201-0.379,0.35-0.663,0.445-0.855l0.442-0.834 c0.379-0.733,0.854-1.574,1.422-2.521l3.231-5.418h5.436L15.383,18.945z M25.164,10.014c0-0.82,0.111-1.316,0.334-1.49 c0.223-0.176,1.02-0.387,2.393-0.637c1.664-0.309,2.729-0.693,3.189-1.15c0.461-0.459,0.689-1.366,0.689-2.723 c0-1.436-0.303-2.41-0.908-2.924c-0.607-0.512-1.762-0.771-3.457-0.771c-1.738,0-2.91,0.255-3.516,0.763 c-0.604,0.506-0.906,1.492-0.906,2.957v0.485h2.174V4.03c0-0.85,0.127-1.385,0.383-1.604c0.258-0.219,0.875-0.33,1.854-0.33 c0.953,0,1.557,0.109,1.811,0.326c0.254,0.217,0.381,0.729,0.381,1.539c0,0.842-0.127,1.365-0.381,1.567 c-0.254,0.201-1.066,0.427-2.439,0.671c-1.658,0.297-2.709,0.646-3.15,1.051c-0.439,0.405-0.66,1.225-0.66,2.455v2.377h8.82v-1.754 h-6.607L25.164,10.014L25.164,10.014z"/> </g> </svg>
				</button>
			</a-tooltip>

			<!-- Subscript button -->
			<a-tooltip overlayClassName="change-tooltip-color" title="Subscript" placement="top">
				<button @click="editor.chain().focus().toggleSubscript().run()" :class="{ 'is-active': editor.isActive('subscript') }">
					<svg :fill="editor.isActive('subscript') ? '#000' : '#aaa'" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 32.52 32.52" xml:space="preserve"> <g> <path d="M22.662,1.194l-7.282,11.74l7.762,12.506h-5.397l-3.481-5.826c-0.532-0.898-1.042-1.812-1.528-2.735l-0.496-0.959 l-0.516-0.941h-0.07l-0.516,0.959c-0.616,1.163-1.314,2.387-2.098,3.678l-3.498,5.826H0l7.958-12.506L0.569,1.195h5.487l3.231,5.4 c0.511,0.852,0.99,1.693,1.438,2.521l0.445,0.852l0.444,0.854h0.069c0.201-0.379,0.349-0.662,0.444-0.854l0.443-0.834 c0.379-0.734,0.854-1.574,1.422-2.521l3.232-5.418L22.662,1.194L22.662,1.194z M32.52,29.571v1.754h-8.82v-2.377 c0-1.229,0.222-2.049,0.662-2.455c0.441-0.402,1.492-0.754,3.15-1.051c1.373-0.244,2.186-0.471,2.439-0.67 c0.254-0.203,0.379-0.729,0.379-1.568c0-0.811-0.125-1.32-0.379-1.537c-0.254-0.219-0.855-0.326-1.81-0.326 c-0.979,0-1.6,0.109-1.854,0.33c-0.256,0.219-0.385,0.754-0.385,1.604v0.494H23.73v-0.486c0-1.465,0.303-2.451,0.905-2.957 c0.604-0.508,1.774-0.762,3.515-0.762c1.696,0,2.852,0.258,3.457,0.77c0.606,0.514,0.911,1.488,0.911,2.924 c0,1.355-0.231,2.265-0.692,2.724c-0.461,0.456-1.523,0.843-3.188,1.149c-1.373,0.25-2.17,0.461-2.396,0.637 c-0.223,0.176-0.332,0.67-0.332,1.49v0.316L32.52,29.571L32.52,29.571z"/> </g> </svg>
				</button>
			</a-tooltip>

            <!-- <button @click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">
                p
            </button> -->
            <!-- <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
                H
            </button> -->
            <!-- <button @click="editor.chain().focus().undo().run()">
                undo
            </button>
            <button @click="editor.chain().focus().redo().run()">
                redo
            </button> -->
        </div>

        <editor-content v-model="content" :editor="editor" />

    </div>
</template>

<script>
    import {
        Editor,
        EditorContent,
        BubbleMenu,
        FloatingMenu,
    } from '@tiptap/vue-2'
    import TextAlign from '@/assets/TipTapExtensions/TextAlign'
    import FontWeight from '@/assets/TipTapExtensions/FontWeight'
    import LineHeight from '@/assets/TipTapExtensions/LineHeight'
    import TextStyle from '@tiptap/extension-text-style'
	import Superscript from '@tiptap/extension-superscript'
	import Subscript from '@tiptap/extension-subscript'
    import Underline from '@tiptap/extension-underline'
	import OrderedList from '@tiptap/extension-ordered-list'
	import BulletList from '@tiptap/extension-bullet-list'
	import Heading from '@tiptap/extension-heading'
    import StarterKit from '@tiptap/starter-kit'
    import Link from '@tiptap/extension-link'
    import ListItem from '@tiptap/extension-list-item'
    import MoreActions from 'bh-mod/components/common/MoreActions'


    export default {
        components: {
            MoreActions,
            EditorContent,
            BubbleMenu,
            FloatingMenu,
        },
        props:{
            builder:{
                type:String,
                default:'web'
            },
            value: {
                type: String,
                default: '',
            },
			all: {
				type: Boolean,
				default: false
			}
        },
        watch:{
            value(value) {
            // HTML
            const isSame = this.editor.getHTML() === value

            // JSON
            // const isSame = this.editor.getJSON().toString() === value.toString()

            if (isSame) {
                return
            }

            this.editor.commands.setContent(this.value, false)
            },
            'form.type'(val){
                if (val === 'section'){
                    this.form.value = this.pageSectionsOptions[0].value
                } else if (val === 'page') {
                    this.form.value = this.pagesOptions[0].value
                } else {
                    this.form.value = ''
                }
            }
        },
        data() {
            return {
                form:{
                    type: "link",
                    value:'',
                },
                heightChoices:[
                    {
                        label:'Small',
                        value:'1'
                    },
                    {
                        label:'Regular',
                        value:'1.25'
                    },
                    {
                        label:'Medium',
                        value:'1.5'
                    },
                    {
                        label:'Large',
                        value:'1.75'
                    },
                    {
                        label:'X-Large',
                        value:'2'
                    },
                ],
				headingChoices:[
                    {
                        label:'H1',
                        value:1
                    },
                    {
                        label:'H2',
                        value:2
                    },
                    {
                        label:'H3',
                        value:3
                    },
                    {
                        label:'H4',
                        value:4
                    },
                    {
                        label:'H5',
                        value:5
                    },
					{
                        label:'H6',
                        value:6
                    },
                ],
                showLinkOptionsBoolean:false,
                editor: null,
                content:'',
            }
        },
        computed:{
            linkType(){
                let types =  [{label:'External Link',value:'link'},{label:'Goto Section',value:'section'}]
                if (!this.$store.getters.isLanding) types.push({label:'Goto Page',value:'page'})
                return types
            },
			pagesOptions(){
				return this.$store.getters.pagesOptions
			},
            pageSectionsOptions(){
                return this.$store.getters.pageSectionsOptions
            },
        },
        methods:{
			toggleBold(){
                console.log('THIS .edit', this.editor.commands)
                return this.editor.commands.toggleBold()
            },
            toggleUnderline(){
                console.log('THIS .edit', this.editor.commands)
                return this.editor.commands.toggleUnderline()
            },
			toggleOrderedList(){
                console.log('THIS .edit', this.editor.commands)
                return this.editor.commands.toggleOrderedList()
            },
			toggleBulletList(){
				console.log('THIS .edit', this.editor.commands)
				return this.editor.commands.toggleBulletList()
			},
            applyHeight(e){
                console.log('line height', e.key)
                this.editor.commands.setHeight(e.key)
            },
			applyHeading(e){
                console.log('heading', e.key)
                this.editor.commands.toggleHeading({level:e.key})
            },
			applyLink(){
				let value = this.form.value
				let target = '_self'

				if (this.form.type === 'section'){
					value = `#sect-${value}`
				}
				if (this.form.type === 'link'){
					target = '_blank'
				}
                if (this.builder === 'web'){
                    if (this.form.value.trim()){
                        this.setLink(value, target);
                    }
                } else {

                }
                this.showLinkOptionsBoolean = false
            },
            showLinkOptions(){
                console.log('HELLO')
                if (this.editor.isActive('link')) {
                    return this.editor.chain().focus().unsetLink().run()
                    // return this.editor
                    // .chain()
                    // .focus()
                    // .updateAttributes('link', {
                    //     href: 'https://duckduckgo.com'
                    // })
                }

                this.showLinkOptionsBoolean = true
                let url = ''
                if (this.builder == 'email'){
                    url = this.editor.getAttributes('link').href
                }
                this.form.value = url

            },
            setLink(url, target = '_self') {
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .setLink({ href: url, target: target })
                    .run()
            }
        },
        mounted() {
            console.log('FontWeight, => ',FontWeight)
            this.content = this.value
			this.editor = new Editor({
				content: this.value,
				extensions: [
					StarterKit,
					LineHeight,
					TextStyle,
					FontWeight,
					Underline,
					OrderedList,
  					BulletList,
					Heading,
					Superscript,
					Subscript,
					TextAlign.configure({
						types: ['heading', 'paragraph'],
					}),
					ListItem.configure({
						HTMLAttributes: {
							class: 'my-custom-class',
						},
					}),
					Link.configure({
						openOnClick: false,
						HTMLAttributes: {
							class: 'my-custom-class',
							style:'text-decoration:none;',
						},
					}),
				],
				onUpdate: () => {
					// HTML
					this.$emit('input', this.editor.getHTML())

					// JSON
					// this.$emit('input', this.editor.getJSON())
				},
			})
        },
        destroyEditor() {
			if (this.editor && !this.editor.isDestroyed) {
				this.editor.destroy();
				this.editor = null;
			}
		},
    }
</script>



<style lang="scss">
.rich_text-menubar{
    padding: 4px 8px;
    background: var(--light-bg-gray);
    button{
        all: unset;
        width: 1.5rem;
        height: 1.5rem;
        color: #0d0d0d;
        border: none;
        background-color: transparent;
        border-radius: .4rem;
        padding: .25rem;
        cursor:pointer;
    }
}
.ProseMirror {
    height:350px;
    overflow-y:scroll;
    border:1px solid #aaa;
    padding:10px;
    &:focus-visible{
        outline:none;
        border:1px solid #aaa;
        padding:10px;
    }

    > * + * {
        margin-top: 0.75em;
    }
    p{
        margin-bottom:0;
        line-height:1;
        // &+ p{
        //     margin-top:0;
        // }
        &:after{
        content:'⁋';
        color:#bfbfbf;
        }
    }
    ul,
    ol {
        padding: 0 1rem;
        li > p{
        margin-bottom:0;
        }
    }

    blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(#0D0D0D, 0.1);
    }
}

.bubble-menu {
  display: flex;
  background-color: #0D0D0D;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    color: #FFF;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}

.floating-menu {
  display: flex;
  background-color: #0D0D0D10;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}
</style>
