import { Extension } from '@tiptap/core';
import '@tiptap/extension-text-style'

   

export default Extension.create({
        name: 'fontWeight',

        defaultOptions: {
            types: ['textStyle'],
        },

        addGlobalAttributes() {
            return [
                {
                    types: this.options.types,
                    attributes: {
                        fontWeight: {
                            default: null,
                            parseHTML: element => element.style.fontWeight.replace(/['"]+/g, ''),
                            renderHTML: attributes => {
                                if (!attributes.fontWeight) {
                                    return {}
                                }

                                return {
                                    style: `font-weight: ${attributes.fontWeight}`,
                                }
                            },
                        },
                    },
                },
            ]
        },

        addCommands() {
            return {
            setWeight: weight => ({ chain }) => {
                return chain()
                .setMark('textStyle', { fontWeight:weight })
                .run()
            },
            unsetWeight: () => ({ chain }) => {
                return chain()
                .setMark('textStyle', { fontWeight: null })
                .removeEmptyTextStyle()
                .run()
            },
            }
        },
    })